import { BaseHeader, BaseHeaderProps } from '../base-header';
import { HeaderBackIconButton, HeaderHelpChip } from '../components';

export type HeaderWithBackAndHelpProps = Omit<
  BaseHeaderProps,
  'leftElement' | 'rightElement'
>;

export function HeaderWithBackAndHelp(props: HeaderWithBackAndHelpProps) {
  return (
    <BaseHeader
      {...props}
      leftElement={<HeaderBackIconButton />}
      rightElement={<HeaderHelpChip />}
    />
  );
}
