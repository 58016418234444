import { BaseHeader, BaseHeaderProps } from '../base-header';
import { HeaderHelpChip } from '../components';
import { HeaderLogo } from '../header-logo';

export type HeaderWithLogoAndHelpProps = Omit<
  BaseHeaderProps,
  'leftElement' | 'rightElement'
>;

export function HeaderWithLogoAndHelp(props: HeaderWithLogoAndHelpProps) {
  return (
    <BaseHeader
      {...props}
      leftElement={<HeaderLogo />}
      rightElement={<HeaderHelpChip />}
    />
  );
}
