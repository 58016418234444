'use client';

import { SigninForm } from '@/features/authentication/signin/components';

import { HeaderHelpChip } from '@/ui/components/header/components';
import { HeaderWithLogo } from '@/ui/components/header/variants';
import { Page, PageSubtitle, PageTitle } from '@/ui/components/page';

import { ROUTES } from '@/shared/routes';

import { Link } from '@/lib/navigation';

export default function SigninPage() {
  return (
    <>
      <HeaderWithLogo
        rightElement={<HeaderHelpChip />}
        className="py-units-unit10 px-units-unit16 md:py-units-unit24 md:px-units-unit80"
      />
      <Page gap="units-unit40" className="flex-grow justify-start">
        <PageTitle
          align={'left'}
          title={'Log in'}
          subtitle={
            <PageSubtitle align={'left'} color={'content-subtext-default'}>
              Continue using your mobile number.
              <br />
              New to Moniepoint?{' '}
              <Link
                className={'inline-block text-primary-l7'}
                href={ROUTES.SIGN_UP.INDEX}
              >
                Sign up
              </Link>
            </PageSubtitle>
          }
        />

        <SigninForm />
      </Page>
    </>
  );
}
