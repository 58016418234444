import { BaseHeader, BaseHeaderProps } from '../base-header';
import { HeaderBackIconButton, HeaderHelpChip } from '../components';
import { HeaderLogo } from '../header-logo';

export type HeaderWithBackLogoAndHelpProps = Omit<
  BaseHeaderProps,
  'leftElement' | 'rightElement' | 'children'
>;

export function HeaderWithBackLogoAndHelp(
  props: HeaderWithBackLogoAndHelpProps,
) {
  return (
    <BaseHeader
      {...props}
      leftElement={<HeaderBackIconButton />}
      rightElement={<HeaderHelpChip />}
    >
      <HeaderLogo className="hidden md:block" />
    </BaseHeader>
  );
}
